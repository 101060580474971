import { useState } from 'react'

import _ from 'lodash'
import {
    Button,
    HStack,
    Input,
    Radio,
    RadioGroup,
    VStack
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

import type { IsoTime } from '../types/utility'

const defaultSettings = {
    workFilter: 'hide',
    privateFilter: 'include'
}
const storedSettings = localStorage.getItem('settings')
export const initialSettings = storedSettings ? JSON.parse(storedSettings) : defaultSettings
localStorage.setItem('settings', JSON.stringify(initialSettings))

type Location = {
    id: string,
    latitude: number
    longitude: number
    comment?: string,
    tags: string[],
    createdAt: IsoTime
}

export default function Settings() {
    const [settings, setSettings] = useState(initialSettings)
    const [locationComment, setLocationComment] = useState('')
    const [locations, setLocations] = useState([] as Location[])
    const makeHandleSettingChange = (settingName: string) => (value: string) => {
        const newSettings = {
            ...settings,
            [settingName]: value
        }
        setSettings(newSettings)
        localStorage.setItem('settings', JSON.stringify(newSettings))
    }
    return (
        <div>
        <HStack>
        <VStack align='left'>
            <div>Work filter</div>
            <div>Private filter</div>
        </VStack>
        <VStack align='left'>
            <RadioGroup
                onChange={makeHandleSettingChange('workFilter')}
                value={settings.workFilter}
            >
                <HStack>
                    <Radio value='include'>Include</Radio>
                    <Radio value='hide'>Hide</Radio>
                    <Radio value='only'>Only</Radio>
                </HStack>
            </RadioGroup>
            <RadioGroup
                onChange={makeHandleSettingChange('privateFilter')}
                value={settings.privateFilter}
            >
                <HStack>
                    <Radio value='include'>Include</Radio>
                    <Radio value='hide'>Hide</Radio>
                    <Radio value='only'>Only</Radio>
                </HStack>
            </RadioGroup>
        </VStack>
        </HStack>
        <VStack>
        <HStack maxWidth='400px' marginTop='10px'>
            <Input
                placeholder="Comment on location"
                value={locationComment}
                onChange={e => setLocationComment(e.target.value)}
            />
            <Button onClick={() => {
                const location = navigator.geolocation
                if (!location) {
                    alert('No navigator.geolocation')
                    return
                }
                location.getCurrentPosition((position) => {
                    const requiredData = _.pick(position.coords,
                        ['latitude', 'longitude'])
                    const data = locationComment ?
                        { ...requiredData, comment: locationComment } :
                        requiredData
                    fetch(`${baseUrl}/locations`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response => {
                        if (!response.ok) {
                            setLocationComment('Error')
                        } else {
                            setLocationComment('')
                        }
                    })
                })
            }}>
            Locate
            </Button>
        </HStack>
        <Button
            onClick={() => {
                if (!locations.length) {
                    fetch(`${baseUrl}/locations`)
                    .then(response => response.json())
                    .then(setLocations)
                } else {
                    setLocations([])
                }
            }}
        >
            {!locations.length ? 'Show' : 'Hide'} locations
        </Button>
        {
            locations.map(location => {
                const lat = Number(location.latitude).toFixed(4).toString()
                const lng = Number(location.longitude).toFixed(4).toString()
                return (
                <div key={location.id}>
                    {dayjs(location.createdAt).format('dddd, MM/D [at] h:mm A: ')}
                    <a href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
                        target="_blank" rel="noreferrer"
                    >{lat + ', ' + lng}
                    </a>
                    {' (' + location.comment + ')'}
                </div>
            )})
        }
        </VStack>
        </div>
    )
}