import { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Center,
    CloseButton,
    HStack,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
    Tabs,
    Tag,
    TagLabel,
    TagRightIcon,
    Text,
    VStack,
} from '@chakra-ui/react'
import _ from 'lodash'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import TaskInput from './TaskInput'
import TaskOccurrenceCard from './TaskOccurrenceCard'
import TaskOccurrenceCardStackFilters from './TaskOccurrenceCardStackFilters'
import TaskSelect from './TaskSelect'

import { FullTaskOccurrence } from '../types/models'
import { Filter, BinaryFilter, RangeFilter, IsoDate, StateUser } from '../types/utility'

const fetchOccurrences = async (handleOccurrences: StateUser<(FullTaskOccurrence|null)[]>) => {
    const response = await fetch(`${baseUrl}/tasks/occurrences`)
    const data = await response.json()
    handleOccurrences(data)
}

function TaskOccurrenceCardStack() {
    const [tabIndex, setTabIndex] = useState(0)
    const [fOccurrences, setOccurrences] = useState<(FullTaskOccurrence|null)[]>([])
    const [hiddenHeaders, setHiddenHeaders] = useState<string[]>([])
    const [collapsedTasks, setCollapsedTasks] = useState<string[]>([])
    const [filters, setFilters] = useState<Filter[]>([])
    const updateOccurrences = () => { fetchOccurrences(setOccurrences) }
    useEffect(updateOccurrences, [])

    // The nulls are a hack so that I can manually trigger a re-render by pushing one to the array
    let occurrences = fOccurrences.filter(o => o !== null) as FullTaskOccurrence[]

    if (localStorage.getItem('collapsedTasks')) {
        const localValue = JSON.parse(localStorage.getItem('collapsedTasks')!)
        if (collapsedTasks.join(', ') !== localValue.join(', ')) {
            setCollapsedTasks(JSON.parse(localStorage.getItem('collapsedTasks')!))
        }
    } else {
        localStorage.setItem('collapsedTasks', JSON.stringify([]))
    }

    function isBinaryFilter(f: Filter): f is BinaryFilter {
        return (f as BinaryFilter).positive !== undefined;
    }
    const [binaryFilters, rangeFilters] = _.partition(filters, isBinaryFilter)
    const nameFilter = filters.find(f => f.type === 'name') as BinaryFilter | undefined
    if (nameFilter) {
        occurrences = occurrences.filter(o => o.task.name.match(nameFilter.value))
    }
    const binaryFiltersExceptName = binaryFilters.filter(f => f.type !== 'name')
    const [
        [positiveGoalFilters = [], positiveTagFilters = []],
        [negativeGoalFilters = [], negativeTagFilters = []]
    ] = _.partition(binaryFiltersExceptName, f => f.positive).map(
        f => _(f)
        .groupBy('type').entries().sortBy(0).map(([_, v]) => v)
        .map(filterGroups => filterGroups.map(f => f.value)).value()
    )
    if (positiveTagFilters.length || negativeTagFilters.length) {
        occurrences = occurrences.filter(o => {
            const taskTags = o.task.tags
            return (
                positiveTagFilters.every(tag => taskTags.includes(tag)) &&
                !negativeTagFilters.some(tag => taskTags.includes(tag))
            )
        })
    }
    if (positiveGoalFilters.length || negativeGoalFilters.length) {
        occurrences = occurrences.filter(o => {
            const taskGoals = o.task.goals.map(g => g.name)
            return (
                positiveGoalFilters.every(goal => taskGoals.includes(goal)) &&
                !negativeGoalFilters.some(goal => taskGoals.includes(goal))
            )
        })
    }
    if (rangeFilters.length) {
        occurrences = occurrences.filter(o => {
            const task = o.task
            return rangeFilters.every(f => {
                const value = task[f.type]
                return f.value.includes(value)
            })
        })
    }
    occurrences = occurrences.filter(occurrence => {
        const settings = JSON.parse(localStorage.getItem('settings')!)
        const { workFilter, privateFilter } = settings
        const tags = occurrence.task.tags
        if ((workFilter === 'hide' && tags.includes('work')) ||
            (privateFilter === 'hide' && tags.includes('private'))) return false
        if ((workFilter === 'only' && !tags.includes('work')) ||
            (privateFilter === 'only' && !tags.includes('private'))) return false
        return true
    })

    const collapsedOccurrences = _(occurrences)
    .filter(o => collapsedTasks.includes(o.task.id) ||
        (!!o.activeTime && o.activeTime > dayjs().toISOString()) ||
        (!!o.date && o.date > dayjs().format('YYYY-MM-DD'))
    )
    .orderBy(o => o.activeTime || dayjs(o.date).toISOString() || '2099-01-01')
    .value()
    const collapsedOccurrenceIds = collapsedOccurrences.map(o => o.id)

    const undatedOccurrences = occurrences
    .filter(o => !collapsedOccurrenceIds.includes(o.id))
    .filter(o => !o.date)
    .filter(o => !o.completedAt || o.task.isRecurring)
    .filter(o => !collapsedTasks.includes(o.task.id))

    const occurrencesByDate = _(occurrences)
    .filter(o => !!o.date)
    .filter(o => !o.completedAt)
    .filter(o => !collapsedOccurrenceIds.includes(o.id))
    .groupBy('date')
    .toPairs()
    .orderBy('0') // That is, by ascending date
    .value() as [IsoDate, FullTaskOccurrence[]][]

    const SectionHeader = ({ text, big } : { text: string, big?: boolean }) => (
        <Center py={3}>
            <Text
                color={hiddenHeaders.includes(text) ? 'gray' : 'green.500'}
                textTransform={big ? 'uppercase' : 'none'}
                fontWeight={800}
                fontSize={big ? 'sm' : 'xs'}
                letterSpacing={1.1}
                // Toggle whether it's in hiddenHeaders
                onClick={() => setHiddenHeaders(
                    hiddenHeaders.includes(text) ?
                    hiddenHeaders.filter(h => h !== text) :
                    [...hiddenHeaders, text]
                )}
            >
                {text}
            </Text>
        </Center>
    )

    const OccurrenceSection = ({ occurrencesGroup } : { occurrencesGroup: FullTaskOccurrence[] }) =>
    <VStack>
        {occurrencesGroup.map(occurrence =>
            <TaskOccurrenceCard
                key={occurrence.id}
                occurrence={occurrence}
                occurrences={occurrences}
                setOccurrences={setOccurrences}
                updateOccurrences={updateOccurrences}
                filters={filters}
                setFilters={setFilters}
            />
        )}
    </VStack>

    const CollapsedOccurrenceSection = ({ occurrencesGroup } : { occurrencesGroup: FullTaskOccurrence[] }) =>
    <Accordion allowToggle>
        {occurrencesGroup.map(occurrence =>
            <AccordionItem key={occurrence.id}>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            {occurrence.task.name}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <TaskOccurrenceCard
                            key={occurrence.id}
                            occurrence={occurrence}
                            occurrences={occurrences}
                            setOccurrences={setOccurrences}
                            updateOccurrences={updateOccurrences}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </AccordionPanel>
            </AccordionItem>
        )}
    </Accordion>

    const ChooseInputSection = () => (
        <Tabs
            isLazy={true}
            index={tabIndex}
            onChange={index => { setTabIndex(index) }}
        >
            <Box overflow="auto">
                <TabList>
                    <Tab>Create</Tab>
                    <Tab>Select</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <TaskInput updateOccurrences={updateOccurrences} />
                    </TabPanel>
                    <TabPanel>
                        <TaskSelect updateOccurrences={updateOccurrences}/>
                    </TabPanel>
                </TabPanels>
            </Box>
        </Tabs>
    )


    return !occurrences.length ?
        <ChooseInputSection />:
        <VStack>
            <ChooseInputSection />
            <TaskOccurrenceCardStackFilters filters={filters} setFilters={setFilters} />
            <HStack>
                {binaryFilters.filter(f => !f.positive).map(filter =>
                    <Tag
                        key={filter.value}
                        colorScheme='red'
                        size='sm'
                    >
                        <TagLabel>{filter.type.slice(0, 1) + ': ' + filter.value}</TagLabel>
                        <TagRightIcon
                            onClick={() => setFilters(filters.filter(f => f.value !== filter.value))}
                            boxSize='30px'
                            as={CloseButton}
                        />
                    </Tag>
                )}
            </HStack>
            {occurrencesByDate.map(([date, dateOccurrences]) =>
                <div key={date}>
                    <SectionHeader text={dayjs(date).format('dddd, MMMM D')} big={true} />
                    { hiddenHeaders.includes(dayjs(date).format('dddd, MMMM D')) ? null :
                        <OccurrenceSection occurrencesGroup={dateOccurrences} />}
                </div>
            )}
            {!undatedOccurrences.length ? null :
                <div key='undated'>
                    <SectionHeader text='Undated' big={true}/>
                    { hiddenHeaders.includes('Undated') ? null :
                    <OccurrenceSection occurrencesGroup={undatedOccurrences} />}
                </div>
            }
            {!collapsedOccurrences.length ? null :
                <div key='collapsed'>
                    <SectionHeader text='Collapsed' big={true} />
                    { hiddenHeaders.includes('Collapsed') ? null :
                        <CollapsedOccurrenceSection occurrencesGroup={collapsedOccurrences} />}
                </div>
            }
        </VStack>
}

export default TaskOccurrenceCardStack