import { useState } from 'react'
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'

import { IS_DEV } from '../env'

import History from './History'
import Link from './Link'
import Login from './Login'
import GenericCardStack from './GenericCardStack'
import Settings from './Settings'
import TaskOccurrenceCardStack from './TaskOccurrenceCardStack'

function App() {
  const [tabIndex, setTabIndex] = useState(Number(window.localStorage.getItem('tabIndex')))
  const [cookie, setCookie] = useState(document.cookie)

  const cookies = cookie.split(';').map(c => c.trim())
  const hasAuthToken = cookies.some(c => c.startsWith('token='))
  if (!hasAuthToken && !IS_DEV) {
    return <div className="App">
      <Login
        setCookie={setCookie}
      />
    </div>
  }

  // The Box and w="max-content" allow the tabs
  // to scroll when they overflow page width,
  // but are otherwise mysterious to me
  return (
      <div className="App">
        <Tabs
          isLazy={true}
          index={tabIndex}
          onChange={(index) => {
            setTabIndex(index)
            window.localStorage.setItem('tabIndex', index.toString())
          }}
        >
          <Box overflow="auto">
            <TabList w="max-content">
              <Tab>Tasks</Tab>
              <Tab>Goals</Tab>
              <Tab>Tactics</Tab>
              <Tab>Leisure</Tab>
              <Tab>Link</Tab>
              <Tab>History</Tab>
              <Tab>Settings</Tab>
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel>
              <TaskOccurrenceCardStack/>
            </TabPanel>
            <TabPanel>
              <GenericCardStack modelName="goals"/>
            </TabPanel>
            <TabPanel>
              <GenericCardStack modelName="tactics"/>
            </TabPanel>
            <TabPanel>
              <GenericCardStack modelName="leisureActivities"/>
            </TabPanel>
            <TabPanel>
              <Link/>
            </TabPanel>
            <TabPanel>
              <History/>
            </TabPanel>
            <TabPanel>
              <Settings/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
  )
}

export default App
