import { useEffect, useState, ChangeEvent } from 'react'

import {
    Button,
    Editable,
    EditablePreview,
    EditableTextarea,
    Input,
    ListItem,
    Switch,
    UnorderedList,
    VStack
} from '@chakra-ui/react'

import { baseUrl } from '../env'

import type { HistoryEntry } from '../types/models'

const getHistoryEntries = async function() {
    const response = await fetch(`${baseUrl}/history`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return response.json()
}

export default function History() {
    const [historyEntries, setHistoryEntries] = useState<HistoryEntry[]>([])
    const [activeEntryId, setActiveEntryId] = useState<string | null>(null)
    const [activeValue, setActiveValue] = useState<string | null>(null)
    const [includeQuotidian, setIncludeQuotidian] = useState<boolean>(false)
    const [manualEntry, setManualEntry] = useState<string>('')

    useEffect(() => { getHistoryEntries().then(setHistoryEntries) }, [])

    const handleManualEntryChange = (event: ChangeEvent<HTMLInputElement>) => setManualEntry(event.currentTarget.value)

    const settings = JSON.parse(localStorage.getItem('settings')!)

    return (
        <VStack align='left'>
        <Input
            placeholder={'Manual input'}
            value={manualEntry}
            onChange={handleManualEntryChange}
            size='md'
            width='300px'
        />
        <Button
            width='300px'
            colorScheme='blue'
            onClick={() => {
                fetch(`${baseUrl}/history`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ title: manualEntry })
                })
                .then(() => {
                    getHistoryEntries().then(setHistoryEntries)
                    setManualEntry('')
                })
            }}
        >
            Add manual entry
        </Button>
        <Switch
            isChecked={includeQuotidian}
            onChange={() => setIncludeQuotidian(!includeQuotidian)}
        >
            Include quotidian tasks
        </Switch>
        <UnorderedList style={{ marginLeft: '15px' }}>
            {historyEntries
            .filter(historyEntry => includeQuotidian ||
                !historyEntry.tags.includes('quotidian'))
            .filter(historyEntry => {
                const { workFilter, privateFilter } = settings
                const tags = historyEntry.tags
                if ((workFilter === 'hide' && tags.includes('work')) ||
                    (privateFilter === 'hide' && tags.includes('private'))) return false
                if ((workFilter === 'only' && !tags.includes('work')) ||
                    (privateFilter === 'only' && !tags.includes('private'))) return false
                return true
            })
            .map((historyEntry) => {
                return (
                    <ListItem key={historyEntry.id}>
                        <Editable
                            value={
                                activeEntryId === historyEntry.id ?
                                activeValue || '':
                                historyEntry.title
                            }
                            onEdit={() => {
                                setActiveEntryId(historyEntry.id)
                                setActiveValue(historyEntry.details || historyEntry.title)
                            }}
                            onChange={(nextValue) => {
                                setActiveValue(nextValue)
                            }}
                            onSubmit={(nextValue) => {
                                setActiveEntryId(null)
                                if (nextValue === historyEntry.title) return
                                if (nextValue === historyEntry.details) return
                                fetch(`${baseUrl}/history/${historyEntry.id}`, {
                                    method: 'PATCH',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ details: nextValue })
                                })
                                .then(() => { historyEntry.details = nextValue })
                            }}
                        >
                            <EditablePreview />
                            <EditableTextarea />
                        </Editable>
                    </ListItem>
                )
            })}
        </UnorderedList>
        </VStack>
    )
}
